import {Component, Input, ViewChild, ElementRef, Output, EventEmitter, Optional} from '@angular/core';
import {controlValueAccessorProvider} from '../../form/control-value-accessor.functions';
import {AbstractFormControlValueAccessor} from '../../form/abstract-form-control-value-accessor';
import {AbstractControl, ControlContainer, FormControl} from '@angular/forms';

@Component({
  selector: 'sbdl-search-field',
  templateUrl: './search-text-field.component.html',
  styleUrls: ['./search-text-field.component.scss'],
  providers: [
    controlValueAccessorProvider(SearchTextFieldComponent)
  ]
})
export class SearchTextFieldComponent extends AbstractFormControlValueAccessor {
  @Input()
  label: string;

  @Input()
  placeholder: string;

  @Input()
  fontAwesomeIcon: string;

  @Input()
  disabled: boolean;

  @Input()
  submitButtonAriaLabel = '';

  @Input()
  isSmallerText = false;

  @Input()
  searchPage = false;

  @Output()
  submit = new EventEmitter<string>();

  @ViewChild('textField', { static: false })
  textFieldRef: ElementRef;

  private readonly formControl: FormControl = new FormControl('');

  constructor(
    @Optional() private controlContainer: ControlContainer
  ) {
    super();
  }

  get control(): AbstractControl {
    return this.controlContainer != null
      ? this.controlContainer.control
      : this.formControl;
  }

  onSubmitButtonClick() {
    this.submit.emit(this.control.value);
  }
}
