<main id="main" class="landing-content">

    <!-- The waiting spinner  -->
    <div *ngIf="isWaitDisplayed" class="filter-loader">
        <div class="filter-spinner-container">
            <i class="fas fa-spinner fa-pulse" aria-label="Loading..."></i>
        </div>
    </div>


    <div class="landing-header" >


        <!-- The actions at the top of the page -->
        <div class="top-header">
            <sbdl-landing-actions (printPage)="openDialog()" [landingType]="landingType">
            </sbdl-landing-actions>
        </div>

        <!-- The resource logo and title-->
        <span>
            <h1>
                <img class="headerImage" src="{{headerImage}}" aria-hidden="true">
                {{title}}
            </h1>
        </span>
    </div>

    <section class="landing-splits">

        <!-- The left hand side: -->
        <div class="landing-card landing-card-left">

            <!-- The tag line section -->
            <h2 class="title-small">{{landingPage.taglineSection.header}}</h2>
            <dynamic-html class="tagline-text" [content]="landingPage.taglineSection.description">
            </dynamic-html>

            <!-- How will it help section -->
            <h2 class="title-large">How will they help me?</h2>
            <dynamic-html class="howItHelpsSection" [content]="landingPage.howItHelpsSection.description">
            </dynamic-html>
        </div>

        <!-- The right hand side -->
        <div class="landing-card landing-card-right">

          <!-- call to action section -->
          <div class="callToActionBackground">
            <div class="cta-loader" *ngIf="disable">
              <i class="fas fa-spinner fa-pulse" aria-label="Loading..."></i>
            </div>
            <!-- Header -->
            <h2 class="title-small">{{landingPage.callToActionSection.header}}</h2>

            <label class="text-container-3">
              {{landingPage.callToActionSection.description}}
            </label>

            <div *ngIf="landingPage.callToActionSection.version === 'textSearch'">
              <!-- Search text -->
              <div style="margin-top: 10px;">
                <sbdl-search-field isSmallerText="'true'" (submit)="search({query: $event})"
                                   placeholder="{{landingPage.callToActionSection.promptText}}" fontAwesomeIcon="fa-search"
                >
                </sbdl-search-field>
              </div>
              <p style="margin-top: 10px;">
                <a href="javascript:void(0)"
                   (click)="onFilterResourcesClick()">{{landingPage.callToActionSection.browseLinkText}}</a>
              </p>
            </div>

            <div *ngIf="landingPage.callToActionSection.version === 'dropdownSearch'">
              <!-- search ddls -->
              <!-- subject -->
              <div class="select-group">
                <div class="select-control-group selectDDL">
                  <label for="select-a-subject" class="search-label">Select a Subject</label>
                  <select class="select" [ngClass]="{'select-placeholder' : selectedSubject == ''}"
                          [disabled]="disable"
                          [(ngModel)]="selectedSubject"
                          id="select-a-subject"
                          (ngModelChange)="onSubjectFilterChanged()">
                    <option value="">Select a subject</option>
                    <option *ngFor="let s of subjects" value="{{s.code}}">
                      {{ s.fullName }}
                    </option>
                  </select>
                  <i class="far fa-chevron-square-down fa-lg select-icon"></i>
                </div>
              </div>

              <!-- Grade -->
              <div class="select-group">
                <div class="select-control-group selectDDL">
                  <label for="select-a-grade" class="search-label">Select a Subject</label>
                  <select class="select" [ngClass]="{'select-placeholder' : selectedGrade == ''}"
                          [disabled]="disable"
                          [(ngModel)]="selectedGrade"
                          id="select-a-grade"
                          (ngModelChange)="onGradeFilterChanged()"
                          #gradeSelect>
                    <option value="">Select a grade</option>
                    <option *ngFor="let g of grades" value="{{g.code}}">
                      {{ g["longName"] }}
                    </option>
                  </select>
                  <i class="far fa-chevron-square-down fa-lg select-icon"></i>
                </div>
              </div>
              <br>

              <!-- actions to search -->
              <sbdl-button class="primary" (click)="onFilterResourcesSubjectAndGradeClick()">
                GO!
              </sbdl-button>&nbsp;&nbsp;&nbsp;
              <a href="javascript:void(0)" (click)="onFilterResourcesClick()">or browse all</a>
            </div>

            <!-- IAIP AREA -->
            <div *ngIf="landingPage.callToActionSection.version === 'loginPrompt'">
              <!-- NOT Logged in -->
              <br>
              <sbdl-button class="primary attachments-btn" (click)="login()">
                &nbsp;&nbsp;&nbsp;&nbsp;LOG IN&nbsp;&nbsp;&nbsp;&nbsp;
              </sbdl-button>
              <br>
            </div>
            <div *ngIf="landingPage.callToActionSection.version === 'browseIAIP'">
              <!-- Logged on with IAIP access -->
              <br>
              <sbdl-button class="primary attachments-btn" (click)="openInterimItems()">
                <i class="fas fa-badge-check"></i> VIEW INTERIM ITEMS
              </sbdl-button>
              <br>
            </div>
            <div *ngIf="landingPage.callToActionSection.version === 'noIAIP'">
              <!-- Logged on WITHOUT IAIP access -->
              <br>
              <br>
            </div>
          </div>

            <!-- able player or blank space -->
            <div *ngIf="youtubeVideoId.length > 0 && showVideo">
                <sbdl-able-player-float-ts class="video" [youtubeVideoId]="youtubeVideoId">
                </sbdl-able-player-float-ts>
            </div>
            <div style="height: 380px;" *ngIf="youtubeVideoId.length === 0">
            </div>
        </div>
    </section>

    <!-- Start of 'How Can I use this' section  -->
    <section class="landing-how-can-I-use">

        <hr />

        <!-- The images of arrow and spots at the top of the section -->


                    <div class="how-can-I-use-container">

                        <span>
                            <img alt="" src="/assets/images/vector-line.png" class="how-can-I-use-vector">
                            <img alt="" src="/assets/images/vector-arrow.png" class="how-can-I-use-arrow">
                            <h2 class="how-can-I-use-title title-large">{{landingPage.howToUseSection.header}}</h2>
                        </span>
                    </div>


                    <div class="landing-spots">

                        <img class="yellow" src="/assets/images/yellow-spot-6.png" aria-hidden="true">
                        <img class="green" src="/assets/images/green-spot-2.png" aria-hidden="true">
                    </div>


        <!-- The list of items for how can i use -->
        <ul class="howToUseListItems">
            <li *ngFor="let howUse of landingPage.howToUseSection.subSections; index as i;"
                [ngClass]="{'yellowImage' : (i + 1) % 3 == 1,'greenImage' : (i + 1) % 3 == 2,'blueImage' : (i + 1) % 3 == 0}">
                <h3 class="title-small">{{howUse.title}}</h3>
                <dynamic-html class="text-container-2" [content]="howUse.description">
                </dynamic-html>
            </li>
        </ul>

        <hr style="margin-top: 2em;" />

    </section>

    <!-- if the page is too small, display the dive deeper and sample items  -->
    <section *ngIf="wasSmall" class="right-margin-small">

        <div class="center">
            <!-- Deeper section -->
            <div style="margin: 1em;margin-left: 3em;">
                <div *ngIf="landingPage.diveDeeperSection && landingPage.diveDeeperSection.links.length > 0"
                    class="split split-left">
                    <sbdl-landing-dive-deeper [landingPage]="landingPage">
                    </sbdl-landing-dive-deeper>
                </div>

                <!-- Sample sections -->
                <div *ngIf="landingPage.sampleSections && landingPage.sampleSections.sampleResources.length > 0"
                    class="split">
                    <sbdl-landing-sample-items [landingPage]="landingPage">
                    </sbdl-landing-sample-items>
                </div>
            </div>
        </div>
        <br>
        <hr style="clear: both;" />
    </section>
</main>

<!-- Start of the right margin -->
<aside *ngIf="!wasSmall" class="right-margin">
    <div class="scroll">
        <div role="img" attr.aria-label="{{headerImageAlt}}">
          <img class="thumbnail-container" alt="{{headerImageAlt}}" src={{landingPage.marketingGraphicUri}}>
        </div>

        <!-- Deeper section -->
        <sbdl-landing-dive-deeper [landingPage]="landingPage">
        </sbdl-landing-dive-deeper>


        <!-- Sample sections -->
        <sbdl-landing-sample-items [landingPage]="landingPage">
        </sbdl-landing-sample-items>
        <br><br>
    </div>
</aside>

