import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Subject} from 'rxjs';
import {ResourceSummary} from 'src/app/data/resource/model/summary.model';
import {emptyFilters, Filter, SearchFilters} from '../../data/search/search-filters.model';
import {takeUntil} from 'rxjs/operators';

@Component({
  selector: 'sbdl-search-results',
  templateUrl: './search-results.component.html',
  styleUrls: ['./search-results.component.scss']
})
export class SearchResultsComponent implements OnInit, OnDestroy {

  @ViewChild('results', {static: false})
  public resultsElem: ElementRef;
  public allResults: ResourceSummary[];
  public renderedResults: ResourceSummary[];
  public filters: SearchFilters = emptyFilters;
  public defaultFilters: SearchFilters;
  public newSearch = true;
  private readonly destroyed$: Subject<void> = new Subject();
  public isFactorOfThree = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.route.data.pipe(
      takeUntil(this.destroyed$)
    ).subscribe(data => {
      if (data.results) {
        console.log('data', data);
        this.allResults = data.results.results || [];
        if (this.allResults.length % 3 === 0) {
          this.isFactorOfThree = true;
        }
        this.renderedResults = [];
        this.filters = data.results.filters;
        this.defaultFilters = this.addOriginalOrderToFilters(data.results.defaultFilters);

        const params = this.route.snapshot.params;
        this.filters = {...this.filters, query: params.query };
        this.setSelectedFilters(params);

        requestAnimationFrame(this.chunkedRender);
      }
    });

    this.route.params.pipe(
      takeUntil(this.destroyed$)
    ).subscribe(params => {
      this.filters = {...this.filters, query: params.query };
      this.setSelectedFilters(params);
      this.newSearch = (Object.keys(params).length === 0);
    });
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  chunkedRender = () => {
    if (this.renderedResults.length < this.allResults.length) {
      this.renderedResults.push(this.allResults[this.renderedResults.length]);
      requestAnimationFrame(this.chunkedRender);
    }
  }

  scrollToResults() {
    this.resultsElem.nativeElement.scrollIntoView(
      { behavior: 'smooth', block: 'start', inline: 'nearest' });
  }

  private setSelectedFilters(params: any) {
    this.setSelectedParams(params.resourceTypes, this.filters.resourceTypes);
    this.setSelectedParams(params.grades, this.filters.grades);
    this.setSelectedParams(params.subjects, this.filters.subjects);
    this.setSelectedParams(params.claims, this.filters.claims);
    this.setSelectedParams(params.targets, this.filters.targets);
    this.setSelectedParams(params.standards, this.filters.standards);
    this.setSelectedParams(params.domains, this.filters.domains);
    this.setSelectedParams(params.dciStrands, this.filters.dciStrands);
    this.setSelectedParams(params.performanceExpectations, this.filters.performanceExpectations);
  }

  private setSelectedParams(params: string, filters: Filter[]) {
    if (params) {
      const paramCodes = params.split(',');
      filters.forEach(x => x.selected = paramCodes.indexOf(x.code) !== -1);
    }
  }

  getResults(): ResourceSummary[] {
    if (this.filters.resourceTypes && this.filters.resourceTypes.length > 0) {
      if (
        this.filters.resourceTypes[0].code === 'as' ||
        this.filters.resourceTypes[0].code === 'fs' ||
        this.filters.resourceTypes[0].code === 'pl'
      ) {
        if (!this.filters.query) {
          return this.renderedResults.sort(this.sortFunc);
        }
      }
    }
    return this.renderedResults;
  }

  sortFunc(a, b) {
    if ( a.properties.title < b.properties.title ){
      return -1;
    }
    if ( a.properties.title > b.properties.title ){
      return 1;
    }
    return 0;
  }

  private addOriginalOrderToFilters(filters: any): any {
    // Create a deep clone of the filters object to avoid mutating the original object
    const newFilters = JSON.parse(JSON.stringify(filters));

    Object.keys(newFilters).forEach(key => {
      // Check if the property is an array and its elements are objects
      if (Array.isArray(newFilters[key]) && newFilters[key].length > 0 && typeof newFilters[key][0] === 'object') {
        newFilters[key].forEach((item: any, index: number) => {
          item.originalOrder = index;
        });
      }
    });

    return newFilters;
  }
}
