<button
  #button
  mat-button
  matRipple
  class="sbdl-button"
  [attr.disabled]="disabled"
  (blur)="onBlur($event)"
  (click)="click()"
  [attr.aria-label]="ariaLabel"
  [tabindex]="tabIndex"
  [title]="buttonTitle"
  [matRippleColor]="rippleColor"
>
  <span class="sbdl-button__label">
    <ng-content></ng-content>
  </span>
</button>
