import { Component, Input, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';
import {MatRippleModule} from '@angular/material/core';
import {MatRipple} from '@angular/material/core';

@Component({
  selector: 'sbdl-button',
  templateUrl: './button.component.html'
})
export class ButtonComponent {

  @ViewChild('button', { static: true })
  button: ElementRef;

  /**
   * Boolean value indicating if the button should be disabled
   */
  @Input()
  disabled: boolean;

  @Input()
  ariaLabel = '';

  @Input()
  buttonTitle: string;

  @Input()
  tabIndex: string;

  @Input()
  rippleColor: string;

  /**
   * Emits when the button loses focus via [(blur)](https://angular.io/guide/user-input#on-blur)
   */
  @Output()
  blurButton = new EventEmitter();

  onBlur(event: any) {
    this.blurButton.emit(event);
  }

  focus() {
    this.button.nativeElement.focus();
  }

  click() {
    // We have to explicitly call focus on the button because macos firefox & safari
    // does not focus buttons on click.
    // this.button.nativeElement.focus();
  }
}
