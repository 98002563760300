<nav
  [ngClass]="{'closed': closed$}"
  class="navigation-container" role="navigation" aria-label="main" id="navigation-menu-main">
  <div class="watercolor"></div>
  <div class="grid">
    <a
      [routerLink]="[baseHref]"
      title="Tools for Teachers Home Page"
      [attr.aria-label]="'Tools for Teachers Home Page'"
      routerLinkActive=""
      #setAriaCurrentHome="routerLinkActive"
      [attr.aria-current]="setAriaCurrentHome.isActive ? 'page' : undefined"
      tabindex="1"
      class="logo"
      (click)="setTitle('Tools for Teachers - Smarter Balanced'); close()"
    >
      <img class="small" src="/assets/svg/tft-logo-small.svg" aria-hidden="true">
      <img class="full" [src]="(logo$ | async) | trustUrl" aria-hidden="true">
    </a>
    <div class="icons-container mobile-only">
      <div *ngIf="closed$">
        <a
          class="hamburger"
          (click)="toggleOpen()"
        >
          <div class="imageArea">
            <i class="far fa-bars center" aria-hidden="true"></i>
          </div>
        </a>
      </div>
      <div *ngIf="!closed$">
        <a
          class="xmark"
          (click)="toggleOpen()"
        >
          <div class="imageArea">
            <i class="far fa-times center" aria-hidden="true"></i>
          </div>
        </a>
      </div>
    </div>
    <div class="mobile-only login-button">
      <button
        *ngIf="!(isAuthenticated$ | async)"
        title="Login"
        [attr.aria-label]="'Login'"
        routerLinkActive #rlaLogin="routerLinkActive"
        #setAriaCurrentLogin="routerLinkActive"
        [attr.aria-current]="setAriaCurrentLogin.isActive ? 'page' : undefined"
        (click)="setTitle('Login: Tools for Teachers - Smarter Balanced'); login(); close()"
      >Login</button>
    </div>
    <ul class="links links-top">
      <li>
      <a
        title="Find Resources"
        [attr.aria-label]="'Find Resources'"
        [routerLink]="[baseHref, 'search']"
        routerLinkActive #rlaSearch="routerLinkActive"
        [ngClass]="{'selectedBackgroundIsActive': rlaSearch.isActive,
                   'selectedBackgroundNotActive': !rlaSearch.isActive}"
        #setAriaCurrentSearch="routerLinkActive"
        [attr.aria-current]="setAriaCurrentSearch.isActive ? 'page' : undefined"
        (click)="setTitle('Search: Tools for Teachers - Smarter Balanced'); close()"
      >
      <div class="imageArea">
        <i class="far fa-search center" aria-hidden="true"></i>
      </div>
      <span>Find Resources</span>
      </a>
      </li>

      <li>
      <a
        title="Interim Connections Playlists"
        [attr.aria-label]="'Interim Connections Playlists'"
        [routerLink]="[baseHref, 'landing', 'playlist']"
        routerLinkActive #rlaPlaylist="routerLinkActive"
        [ngClass]="{'selectedBackgroundIsActive': rlaPlaylist.isActive,
                   'selectedBackgroundNotActive': !rlaPlaylist.isActive}"
        #setAriaCurrentPlaylist="routerLinkActive"
        [attr.aria-current]="setAriaCurrentPlaylist.isActive ? 'page' : undefined"
        (click)="close()"
        >
        <div class="imageArea">
          <i class="far fa-chart-network center" aria-hidden="true" style="margin: 0 3.9px;"></i>
        </div>
        <span>Interim Connections Playlists</span>
      </a>
      </li>

      <li>
      <a
        title="Instructional Resources"
        [attr.aria-label]="'Instructional Resources'"
        [routerLink]="[baseHref, 'landing', 'instructional']"
        routerLinkActive #rlaInstructional="routerLinkActive"
        [ngClass]="{'selectedBackgroundIsActive': rlaInstructional.isActive,
                   'selectedBackgroundNotActive': !rlaInstructional.isActive}"
        #setAriaCurrentInstructional="routerLinkActive"
        [attr.aria-current]="setAriaCurrentInstructional.isActive ? 'page' : undefined"
        (click)="close()"
        >
        <div class="imageArea" >
          <sbdl-icon icon="steps" aria-hidden="true"  class="center"></sbdl-icon>
        </div>
        <span>Instructional Resources</span>
      </a>
      </li>

      <li>
      <a
        title="Formative Strategies"
        [attr.aria-label]="'Formative Assessment Strategies'"
        [routerLink]="[baseHref, 'landing', 'formative']"
        routerLinkActive #rlaFormative="routerLinkActive"
        [ngClass]="{'selectedBackgroundIsActive': rlaFormative.isActive,
                   'selectedBackgroundNotActive': !rlaFormative.isActive}"
        #setAriaCurrentFormative="routerLinkActive"
        [attr.aria-current]="setAriaCurrentFormative.isActive ? 'page' : undefined"
        (click)="close()"
      >
        <div class="imageArea">
          <sbdl-icon icon="strategies" aria-hidden="true" class="center"></sbdl-icon>
        </div>
        <span>Formative Strategies</span>
      </a>
      </li>

      <li>
      <a
        title="Accessibility Strategies"
        [attr.aria-label]="'Accessibility Strategies'"
        [routerLink]="[baseHref, 'landing', 'accessibility']"
        routerLinkActive #rlaAccessibility="routerLinkActive"
        [ngClass]="{'selectedBackgroundIsActive': rlaAccessibility.isActive,
                   'selectedBackgroundNotActive': !rlaAccessibility.isActive}"
        #setAriaCurrentAccessibility="routerLinkActive"
        [attr.aria-current]="setAriaCurrentAccessibility.isActive ? 'page' : undefined"
        (click)="close()"
      >
        <div class="imageArea">
          <i class="far fa-universal-access center" aria-hidden="true"></i>
        </div>
        <span>Accessibility Strategies</span>
      </a>
      </li>

      <li>
      <a
        title="Professional Learning Resources"
        [attr.aria-label]="'Professional Learning Resources'"
        [routerLink]="[baseHref, 'landing', 'professional']"
        routerLinkActive #rlaProfessional="routerLinkActive"
        [ngClass]="{'selectedBackgroundIsActive': rlaProfessional.isActive,
                   'selectedBackgroundNotActive': !rlaProfessional.isActive}"
        #setAriaCurrentProfessional="routerLinkActive"
        [attr.aria-current]="setAriaCurrentProfessional.isActive ? 'page' : undefined"
        (click)="close()"
      >
        <div class="imageArea">
          <sbdl-icon
            icon="goal"
            class="center"
            aria-hidden="true"></sbdl-icon>
        </div>
        <span>Professional Learning Resources</span>
      </a>
      </li>

      <li>
      <a
        title="Interim Assessment Item Portal"
        [attr.aria-label]="'Interim Assessment Item Portal'"
        [routerLink]="[baseHref, 'landing', 'items']"
        routerLinkActive #rlaItems="routerLinkActive"
        [ngClass]="{'selectedBackgroundIsActive': rlaItems.isActive,
                   'selectedBackgroundNotActive': !rlaItems.isActive}"
        #setAriaCurrentInterim="routerLinkActive"
        [attr.aria-current]="setAriaCurrentInterim.isActive ? 'page' : undefined"
        (click)="close()"
        >
        <div class="imageArea">
          <i class="fas fa-badge-check center" aria-hidden="true"></i>
        </div>
        <span>Interim Assessment Item Portal</span>
      </a>
      </li>
    </ul>

    <div class="links links-bottom">
      <a
        *ngIf="(isAuthenticated$ | async)"
        class="auto-margin"
        title="Bookmarks & Notes"
        [attr.aria-label]="'Bookmarks & Notes'"
        [routerLink]="[baseHref, 'bookmarks']"
        routerLinkActive #rlaBookmarks="routerLinkActive"
        [ngClass]="{'selectedBackgroundIsActive': rlaBookmarks.isActive,
                   'selectedBackgroundNotActive': !rlaBookmarks.isActive}"
        #setAriaCurrentBookmarks="routerLinkActive"
        [attr.aria-current]="setAriaCurrentBookmarks.isActive ? 'page' : undefined"
        (click)="close()"
      >
        <div class="imageArea">
          <i class="far fa-bookmark center" aria-hidden="true"></i>
        </div>
        <span>Bookmarks &amp; Notes</span>
      </a>
      <button
        class="test large-only"
        *ngIf="!(isAuthenticated$ | async)"
        title="Login"
        [attr.aria-label]="'Login'"
        (click)="login()"
        routerLinkActive #rlaLogin="routerLinkActive"
        [ngClass]="{'selectedBackgroundIsActive': rlaLogin.isActive,
                   'selectedBackgroundNotActive': !rlaLogin.isActive}"
        #setAriaCurrentLogin="routerLinkActive"
        [attr.aria-current]="setAriaCurrentLogin.isActive ? 'page' : undefined"
        (click)="setTitle('Login: Tools for Teachers - Smarter Balanced'); close()"
      >
        <i class="far fa-sign-in-alt center" aria-hidden="true"></i>
        <span>Login</span>
      </button>
      <a
        class="test"
        *ngIf="(isAuthenticated$ | async)"
        title="Logout"
        [attr.aria-label]="'Logout'"
        routerLinkActive #rlaLogout="routerLinkActive"
        [ngClass]="{'selectedBackgroundIsActive': rlaLogout.isActive,
                   'selectedBackgroundNotActive': !rlaLogout.isActive}"
        #setAriaCurrentLogout="routerLinkActive"
        (click)="signOut(); close()"
      >
        <i class="far fa-sign-out-alt center" aria-hidden="true"></i>
        <span>Logout</span>
      </a>
    </div>
  </div>
</nav>
