<div
  #textField
  class="sbdl-search-field placeholder-text"
  [ngClass]="{'smaller_outline': isSmallerText, 'not-home-page': searchPage}"
>
  <label class="text-field-accessible-label" for="tf-outlined">{{placeholder}}</label>
  <input
    [ngClass]="{'smaller_input': isSmallerText}"
    id="tf-outlined"
    autocomplete="off"
    type="text"
    [formControl]="control"
    (keydown.enter)="onSubmitButtonClick()"
    [attr.disabled]="disabled"
    [placeholder]="placeholder || ''"
  >
<!--  <div class="custom-placeholder">-->
<!--    {{ placeholder }}-->
<!--  </div>-->
  <sbdl-button
    [ngClass]="{'smaller_button': isSmallerText,  'not-home-page-button': searchPage}"
    class="primary"
    aria-label="Start the search and display results"
    (click)="onSubmitButtonClick()"
    [attr.disabled]="disabled"
    [ariaLabel]="submitButtonAriaLabel"
  >
    <!-- TODO why not actually make this a button? -->
    <i
      *ngIf="fontAwesomeIcon"
      class="far {{fontAwesomeIcon}}"
    ></i>
  </sbdl-button>
</div>
