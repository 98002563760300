import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable, throwError} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {AppConfig} from '../common/config/app.config';

const jsonHeaders = {
  'Content-Type':  'application/json'
};

@Injectable()
export class DataService {

  constructor(
    private httpService: HttpClient
  ) { }

  get(url: string, params?: any): Observable<any> {
    const reqCtx = this.makeOptions(url, params);
    return this.httpService.get(reqCtx.fullUrl, reqCtx.options);
  }

  post(url: string, obj: any, params?: any): Observable<any> {
    const reqCtx = this.makeOptions(url, obj, params);
    return this.httpService.post(reqCtx.fullUrl, obj, reqCtx.options);
  }

  // This will do the actual post to the api2pdf site
  // reqCtx.fullUrl will have the url from the consig settings
  // obj: the payload (html, footer, file name and settings)
  // reqCtx.options: the header options needed for the two types of call (Docker or api2pdf endpoint)

  postapi2pdf(url: string, obj: any): Observable<any> {
    const reqCtx = this.makeOptionsapi2pdf();
    return this.httpService.post(reqCtx.fullUrl, obj, reqCtx.options);
  }

  delete(url: string, params?: any): Observable<any> {
    const reqCtx = this.makeOptions(url, params);
    return this.httpService.delete(reqCtx.fullUrl, reqCtx.options);
  }

  downloadBlob(url: string, params?: any): Observable<Blob> {
    const options = {
      headers: new HttpHeaders({ 'Content-Type': 'application/octet-stream' }),
      responseType: 'arraybuffer',
    } as any;

    const reqCtx = this.makeOptions(url, params, options);
    return this.httpService.get(reqCtx.fullUrl, reqCtx.options).pipe(
      map(resp => new Blob([resp])),
      catchError(this.handleError)
    );
  }

  private makeOptions(url: string, params?: any, customOptions?: any): RequestContext {
    const apiBaseUrl = AppConfig.settings.apiServerHost;
    const fullUrl = url.includes('://') ? url : apiBaseUrl + url;

    const options = {
      headers: new HttpHeaders({...jsonHeaders}),
      params,
      ...(customOptions || {})
    };

    return { fullUrl, options };
  }

private makeOptionsapi2pdf(): RequestContext {

  // The endpoint url
  // api2pdf endpoint: https://v2018.api2pdf.com/chrome/html
  // docker image endpoint as of 6/2/2021: https://api2pdf-dev.dl.smarterbalanced.org/pdf/html
  // note: the end of the end points are not the same
  const fullUrl = AppConfig.settings.api2pdfHost;

  // The http header for the api2pdf endpoint
  // the response
  const options2WebService = {
    headers: new HttpHeaders({
        Authorization: AppConfig.settings.api2pdfAuthorization,
        binary: 'true',
        SkipInterceptor: 'true'
      }),
  };

  // The http header for the docker endpoint
  const options2Docker = {
    headers: new HttpHeaders({
      Authorization: AppConfig.settings.api2pdfAuthorization,
      binary: 'true',
      'Content-Type':  'application/octet-stream',
      SkipInterceptor: 'true'
    }),
    responseType : 'arraybuffer',
  } as any;

  const options = AppConfig.settings.api2pdfIsDockerVersion ?  options2Docker : options2WebService;
  return {fullUrl, options};
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }

    // TODO: Log somewhere?
    // return an ErrorObservable with a user-facing error message
    return throwError(() => error);
  }
}

interface RequestContext {
  fullUrl: string;
  options: any;
}
