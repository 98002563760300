<article id="main" class="results-container">
  <sbdl-search
    [filters]="filters"
    [defaultFilters]="defaultFilters"
    [showHeadings]="true"
    [numResults]="allResults.length"
    (goToResults)="scrollToResults()"
    [searchPage]="true"
  ></sbdl-search>
  <section class="resource-number" *ngIf='!newSearch'>
    <div [ngPlural]="allResults.length" [ngClass]="{'no-results': allResults.length === 0}">
      <ng-template ngPluralCase="=0">No results found, try clearing filters or searching for new text.</ng-template>
      <ng-template ngPluralCase="=1"><span class="resource-count">{{ allResults.length }}</span> Result Showing
      </ng-template>
      <ng-template ngPluralCase="other"><span class="resource-count">{{ allResults.length }}</span> Results Showing
      </ng-template>
    </div>
  </section>
  <section #results class="search-results">
    <h2 class="offscreen">Search Results</h2><!-- accessibility -->
    <sbdl-search-result-card
      role="group" aria-label="search result"
      *ngFor="let result of getResults(); let i = index"
      [ngClass]="{'odd': i % 2 !== 0}"
      class="result-card"
      [resource]="result"
    ></sbdl-search-result-card>
    <div class="result-card extra-card"
    >&nbsp;
    </div>
  </section>
</article>

