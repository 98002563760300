import {APP_BASE_HREF} from '@angular/common';
import {Component, OnInit, Inject, HostListener, ElementRef, Renderer2} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {Router} from '@angular/router';
import {Observable, } from 'rxjs';
import {map} from 'rxjs/operators';
import {AppConfig} from 'src/app/common/config/app.config';
import {TenantThemeService} from 'src/app/data/tenant-theme/tenant-theme.service';
import {UserService} from 'src/app/data/user/user.service';
import {User} from 'src/app/data/user/user.model';
import {AuthState, OktaAuth} from '@okta/okta-auth-js';
import {OKTA_AUTH, OktaAuthStateService} from '@okta/okta-angular';
import { matMenuAnimations } from '@angular/material/menu';

@Component({
  selector: 'sbdl-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent implements OnInit {

  logo$: Observable<string>;
  interimItemPortalUrl = '#';
  hasIaipAccess$: Observable<boolean>;
  isAuthenticated$!: Observable<boolean>;
  closed$ = true;

  constructor(
    @Inject(APP_BASE_HREF) public baseHref: string,
    private router: Router,
    private tenantThemeService: TenantThemeService,
    private userService: UserService,
    private titleService: Title,
    private oktaAuthStateService: OktaAuthStateService,
    @Inject(OKTA_AUTH) private oktaAuthService: OktaAuth,
    private elementRef: ElementRef,
    private renderer: Renderer2
  ) {
    this.hasIaipAccess$ = userService.hasIaipRole;
    this.logo$ = this.tenantThemeService.currentTenantTheme$.pipe(
      map(theme => theme.logoUris.full));
  }

  ngOnInit() {
    this.interimItemPortalUrl = AppConfig.settings.interimItemPortalUrl;
    this.isAuthenticated$ = this.userService.authenticated;
  }

  get user$(): Observable<User> {
    return this.userService.user;
  }

  login() {
    this.router.navigate(['/auth/login'], {
      queryParams: {
        redirectUrl: this.router.url
      }});
  }

  setTitle(title: string) {
    this.titleService.setTitle(title);
  }

  signOut() {
    this.router.navigate(['/auth/logout']);
    // this.oktaAuthService.signOut();
  }

  toggleOpen() {
    this.closed$ = !this.closed$;
  }

  close() {
    this.closed$ = true;
  }

}
