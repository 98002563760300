<img class="logo-full"
     [src]="(logo$ | async) || '/assets/svg/tft-logo-full.svg' | trustUrl"
     alt="Tools for Teachers from Smarter Balanced">
<h1>{{ (errorMessage$ | async).title }}</h1>
<p>{{ (errorMessage$ | async).message }}</p>
<div class="error-details emphasis-medium" *ngIf="errorDetails$ | async">
  <p>
    Technical details: {{ errorDetails$ | async }}
  </p>
</div>
<div *ngIf="(user$ | async ) || (error$ | async) == errorTypes.PageNotFound">
  <p>
    <a [routerLink]="['/']">
      <i class='far fa-arrow-left' alt='' aria-hidden='true'></i>
      Take me back to the home page.
    </a>
  </p>
</div>
<div *ngIf="!(user$ | async) &&
     (error$ | async) !== (errorTypes.AuthNoAppAccess && errorTypes.PageNotFound)
      ">
  <sbdl-button
    class="primary"
    (click)="onLoginButtonClick()"
    [ngClass]="{
      processing: loading$ | async
    }"
  >
    <span class="button-content">
      Login
    </span>
    <span class="button-processing-content">
      <i class="fas fa-spinner fa-pulse"></i>
    </span>
  </sbdl-button>
</div>
